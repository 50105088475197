import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { inject } from "@vercel/analytics";

import AuthProvider from "./contexts/AuthProvider";
import Home from "./Pages/Landing/Home/Home";
import Navigation from "./Pages/Shared/Navigation/Navigation";
import Footer from "./Pages/Shared/Footer/Footer";
import Projects from "./Pages/Portfolio/Projects/Projects";
import NotFound from "./Pages/Error/NotFound/NotFound";
import Contact from "./Pages/Contact/Contact";
import Hire from "./Pages/Hire/Hire";
import AboutMe from "./Pages/AboutMe/AboutMe";
import Blogs from "./Pages/Blogs/Blogs/Blogs";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import NewVersion from "./components/NewVersion/NewVersion";
// import Bio from "./Pages/Bio/Bio";
// import Services from "./Pages/Landing/Services/Services";
// import DashboardHome from "./Pages/Dashboard/DashboardHome/DashboardHome";
// import ManageProjects from "./Pages/Dashboard/ManageProjects/ManageProjects";
// import ManageBlogs from "./Pages/Dashboard/ManageBlogs/ManageBlogs";
// import DashboardStats from "./Pages/Dashboard/DashboardStats/DashboardStats";
// import Login from "./Pages/Users/Login/Login";
// import AddBlog from "./Pages/Dashboard/AddBlog/AddBlog";
// import AddProject from "./Pages/Dashboard/AddProject/AddProject";
// import TestComponent from "./Pages/Test/TestComponent/TestComponent";

function App() {
    inject();

    return (
        <div className="App">
            <AuthProvider>
                <BrowserRouter>
                    <NewVersion />
                    <ScrollToTop>
                        <Navigation />
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="home" element={<Home />} />

                            <Route path="projects" element={<Projects />} />
                            <Route path="about" element={<AboutMe />} />
                            {/* <Route path="bio" element={<Bio />} /> */}
                            <Route path="contact" element={<Contact />} />
                            <Route path="hire" element={<Hire />} />
                            <Route path="blogs" element={<Blogs />} />

                            {/* <Route path="dashboard" element={<DashboardHome />} >
                <Route path="" element={<DashboardStats />} />
                <Route path="manage-projects" element={<ManageProjects />} />
                <Route path="manage-blogs" element={<ManageBlogs />} />
                <Route path="add-project" element={<AddProject />} />
                <Route path="add-blog" element={<AddBlog />} />
              </Route> */}

                            {/* <Route path="/login" element={<Login />} /> */}

                            {/* <Route path="/test" element={<TestComponent />} /> */}
                            {/* <Route path="/test" element={<Services />} /> */}

                            <Route path="*" element={<NotFound />} />
                        </Routes>
                        <Footer />
                    </ScrollToTop>
                </BrowserRouter>
            </AuthProvider>
        </div>
    );
}

export default App;
