import React from 'react';
import { Card } from "react-bootstrap";
import styles from './UserProfile.module.css';

import logo from '../../assets/images/logo.jpg';

const user = {
    name: 'Rakib Hasan Babu',
    location: 'Dhaka',
    country: 'Bangladesh',
    profession: 'Web Developer',
    role: 'Junior Software Engineer',
    company: 'TECH V ZERO',
    skills: [
        'HTML5',
        'CSS3',
        'Bootstrap',
        'JavaScript',
        'ES6',
        'React JS',
        'Node JS',
        'Express JS',
        'MongoDB',
        'React Router',
        'Firebase Auth'
    ]
};

const UserProfile = () => {
    return (
        <>
            <Card className={`${styles.profileCard} ${'px-3 py-5 border-0 text-center'}`}>
                <Card.Img variant="top" className={`${styles.profileImg}`} src={logo} alt={user.name} />
                <Card.Body>
                    <Card.Title><span className="fs-3 fw-bold" style={{ color: '#8A2BE2' }}>{user.name}</span></Card.Title>

                    <Card.Text>{user.location}, {user.country}</Card.Text>
                    <Card.Text className="fw-bold">{user.profession}</Card.Text>
                    <hr style={{ color: '#8a2be2' }} />
                    <Card.Text className="fw-bold text-start">KEY SKILLS</Card.Text>
                    {
                        user.skills.map((skill, idx) => <Card.Text
                            key={idx}
                            // title={skill}
                            className={`${'text-start'} ${styles.skillItem}`}
                        >
                            {skill}
                        </Card.Text>)
                    }
                </Card.Body>
            </Card>
        </>
    );
};

export default UserProfile;