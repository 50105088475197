const firebaseConfig = {
    apiKey: "AIzaSyCeXTAp-KaitRxWtoCfq2v5Ji-yfScPZMU",
    authDomain: "portfolio-of-rhb.firebaseapp.com",
    projectId: "portfolio-of-rhb",
    storageBucket: "portfolio-of-rhb.appspot.com",
    messagingSenderId: "801060634763",
    appId: "1:801060634763:web:53bd9b95702f21786dd48e",
    measurementId: "G-0YDKZ46QLP"
};

export default firebaseConfig;