import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Button, Container, Nav, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import styles from "./FeaturedProjects.module.css";

import Project from "../../../Portfolio/Project/Project";
import useProjects from "../../../../hooks/useProjects";

const moreIcon = <FontAwesomeIcon icon={faAngleDoubleRight} />;

const FeaturedProjects = () => {
    const { featuredProjects, loading } = useProjects();

    // const [projects, setProjects] = useState([]);
    // const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    /* useEffect(() => {
        setLoading(true);

        fetch(`https://rhbabu.onrender.com/projects?featured=true`)
            .then(res => res.json())
            .then(data => {
                setProjects(data);
                setLoading(false);
            });
    }, []); */

    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: "ease-out-quad",
        });
    }, []);

    return (
        <div>
            {loading ? (
                <Container
                    style={{
                        minHeight: "60vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spinner
                        animation="grow"
                        variant=""
                        size="sm"
                        style={{ color: "#8a2ce2b3", margin: "0 4px" }}
                    />
                    <Spinner
                        animation="grow"
                        variant=""
                        size="sm"
                        style={{ color: "#8a2ce2cc", margin: "0 4px" }}
                    />
                    <Spinner
                        animation="grow"
                        variant=""
                        size="sm"
                        style={{ color: "#8a2ce2e6", margin: "0 4px" }}
                    />
                </Container>
            ) : (
                <div data-aos="slide-up">
                    <Container>
                        <div className="text-center my-5 py-4">
                            <h2
                                className="fw-bold"
                                style={{ color: "#8a2be2" }}
                            >
                                FEATURED PROJECTS
                            </h2>
                        </div>

                        {featuredProjects?.length > 0 ? (
                            <Row
                                xs={1}
                                sm={1}
                                md={2}
                                lg={3}
                                xl={3}
                                xxl={3}
                                className="mb-4 g-4 g-md-5 g-lg-5 g-xl-5"
                            >
                                {featuredProjects?.map((project) => (
                                    <Project
                                        key={project._id}
                                        project={project}
                                    ></Project>
                                ))}
                            </Row>
                        ) : (
                            <Container
                                style={{
                                    minHeight: "40vh",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <p className="fs-4 text-muted">
                                    No Featured Project Found!
                                </p>
                            </Container>
                        )}
                    </Container>

                    <Container>
                        <Nav>
                            <div className="mx-auto mt-5">
                                <Button
                                    onClick={() => navigate("/projects")}
                                    className={`${
                                        styles.btnExp
                                    } ${"ms-2 ms-md-2"}`}
                                >
                                    {featuredProjects?.length > 0
                                        ? "EXPLORE MORE"
                                        : "EXPLORE PROJECTS"}
                                    <span className="ms-2">{moreIcon}</span>
                                </Button>
                            </div>
                        </Nav>

                        <div className="mt-5 pt-5">
                            <hr />
                        </div>
                    </Container>
                </div>
            )}
        </div>
    );
};

export default FeaturedProjects;
