import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./NewVersion.module.css";

const NewVersion = () => {
    return (
        <div className={`${"py-1"} ${styles.newVersionGlass}`}>
            <Container>
                <div className="text-center my-1">
                    {/* <div className="text-center"> */}
                    <p className="text-secondary">
                        You are viewing the old Portfolio! There might be some
                        <code className="mx-1 fw-bold">API</code>issue.
                        <br />
                        <p className="fw-bold">
                            Check out the new version!{" "}
                            <span className="mr-2"></span>
                            <a
                                href="https://www.rhbabu.dev/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`${styles.redUrl}`}
                            >
                                <span className="fw-normal text-monospace">
                                    rhbabu.dev
                                </span>
                            </a>
                        </p>
                    </p>
                </div>
            </Container>
        </div>
    );
};

export default NewVersion;
