import { useEffect, useState } from "react";

const useProjects = () => {
    const [projects, setProjects] = useState([]);
    const [featuredProjects, setFeaturedProjects] = useState([]);
    const [frontEndProjects, setFrontEndProjects] = useState([]);
    const [fullStackProjects, setFullStackProjects] = useState([]);
    // const [project, setProject] = useState({});
    const [loading, setLoading] = useState(false);

    const typeFe = "FRONT END";
    const typeFs = "FULL STACK";

    /* Features Projects */
    useEffect(() => {
        setLoading(true);

        fetch(`https://rhbabu.onrender.com/projects?featured=true`)
            .then((res) => res.json())
            .then((data) => {
                setFeaturedProjects(data);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setLoading(true);

        fetch(`https://rhbabu.onrender.com/projects?type=${typeFe}`)
            .then((res) => res.json())
            .then((data) => {
                setFrontEndProjects(data);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setLoading(true);

        fetch(`https://rhbabu.onrender.com/projects?type=${typeFs}`)
            .then((res) => res.json())
            .then((data) => {
                setFullStackProjects(data);
                setLoading(false);
            });
    }, []);

    /* Projects */

    useEffect(() => {
        setLoading(true);

        fetch("https://rhbabu.onrender.com/projects")
            .then((res) => res.json())
            .then((data) => {
                setProjects(data);
                setLoading(false);
            });
    }, []);

    /* useEffect(() => {
        setLoading(true);

        fetch(`https://rhbabu.onrender.com/projects/`)
            .then(res => res.json())
            .then(data => {
                setProject(data);
                setLoading(false);
            });
    }, []); */

    /* const singleProject = (_id) => {
        setLoading(true);

        fetch(`https://rhbabu.onrender.com/projects/${_id}`)
            .then(res => res.json())
            .then(data => {
                setProject(data);
                console.log(project);
                setLoading(false);
            });
    }; */

    return {
        projects,
        setProjects,
        featuredProjects,
        setFeaturedProjects,
        frontEndProjects,
        fullStackProjects,
        loading,
        setLoading,
    };
};

export default useProjects;
