import React from 'react';

import Contact from '../../Contact/Contact';
import Banner from '../Banner/Banner';
import FeaturedProjects from '../Featured/FeaturedProjects/FeaturedProjects';
import Skills from "../Skills/Skills/Skills";

const Home = () => {
    return (
        <div>
            <Banner />
            <Skills />
            <FeaturedProjects />
            {/* <Contact></Contact> */}
            <Contact contactTitle="Let me handle your project" />
        </div>
    );
};

export default Home;