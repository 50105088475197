import React, { useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import ProjectModal from '../../Shared/Modal/ProjectModal/ProjectModal';
import styles from './Project.module.css';

const Project = ({ project }) => {
    const { title, thumbImg, type } = project;
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <Col>
                <Card className={`${styles.projectCard} ${'p-3 border-0 h-100 text-center'}`} onClick={() => setModalShow(true)}>
                    <Card.Img variant="top" className={`${styles.thumbImg}`} src={thumbImg} alt={`${title} Logo`} />
                    <Card.Body>
                        <Card.Title><span className="fs-3 fw-bold" style={{ color: '#8A2BE2' }}>{title}</span></Card.Title>

                        <Card.Text className={`${styles.tagBody}`}>
                            <span className={`${styles.tagTitle}`}>{type}</span>
                        </Card.Text>
                    </Card.Body>
                </Card>

                <ProjectModal
                    show={modalShow}
                    project={project}
                    onHide={() => setModalShow(false)}
                />
            </Col>
        </>
    );
};

export default Project;