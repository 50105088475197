import React, { useState } from 'react';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './Navigation.module.css';

import github from '../../../assets/images/github.png';
import logoR from '../../../assets/images/logo-r.png';

const Navigation = () => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    /* const selectedNav = (props, e) => {
        e.preventDefault();

        console.log(props);

        setExpanded(false);
        navigate(props);

        window.scrollTo(0, 0);
    }; */

    const selectedNav = () => {
        setExpanded(false);
    };

    const handleHire = () => {
        navigate('/hire');
        setExpanded(false);
    };

    return (
        <Navbar collapseOnSelect expanded={expanded} expand="lg" className={`${'py-0'} ${styles.navbarGlass}`} variant="light" sticky="top">
            <Container>
                <Navbar.Brand>
                    <img src={logoR} alt="Logo" className={`${styles.logoGlass}`} />
                    {/* <span className={`${'ms-3 fs-2 fw-bold'} $styles.brandText`} style={{ color: '#8a2be2' }}><span>RHB</span></span> */}
                </Navbar.Brand>

                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : true)} aria-controls="responsive-navbar-nav" />

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className={`${styles.navbarNav} ${'me-auto'}`}>
                        <NavLink to="/" onClick={selectedNav} style={({ isActive }) => ({ color: isActive ? '#8A2BE2' : '#2C3E50' })}>HOME</NavLink>
                        <NavLink to="/projects" onClick={selectedNav} style={({ isActive }) => ({ color: isActive ? '#8A2BE2' : '#2C3E50' })}>PROJECTS</NavLink>
                        <NavLink to="/about" onClick={selectedNav} style={({ isActive }) => ({ color: isActive ? '#8A2BE2' : '#2C3E50' })}>ABOUT</NavLink>
                        <NavLink to="/blogs" onClick={selectedNav} style={({ isActive }) => ({ color: isActive ? '#8A2BE2' : '#2C3E50' })}>BLOGS</NavLink>
                        <NavLink to="/contact" onClick={selectedNav} style={({ isActive }) => ({ color: isActive ? '#8A2BE2' : '#2C3E50' })}>CONTACT</NavLink>

                        {/* <NavLink to="/dashboard" style={({ isActive }) => ({ color: isActive ? '#8A2BE2' : '#2C3E50' })}>DASHBOARD</NavLink> */}
                    </Nav>

                    <Nav>
                        <div className="d-lg-flex align-items-center justify-content-center my-2 my-lg-0">
                            <a href="https://github.com/imRHB" target="_blank" rel="noopener noreferrer" className={`${styles.githubLogo}`}>
                                <img src={github} alt="GitHub Logo" style={{ width: '42px', height: '42px', padding: '4px', borderRadius: '50%' }} className={`${styles.githubLogoGlass}`} />
                            </a>

                            <Button
                                onClick={handleHire}
                                className={`${styles.btnHire} ${'ms-4'}`}
                            >
                                HIRE ME
                            </Button>

                            {/* <Button
                                onClick={() => navigate('/hire')}
                                variant=""
                                className={`${styles.btnHire} ${'ms-4'}`}
                            >
                                <Nav className={`${styles.specNav}`}>
                                    <NavLink to="/contact" onClick={() => setExpanded(false)}>HIRE ME</NavLink>
                                </Nav>
                            </Button> */}
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
};

export default Navigation;