import React from "react";
import { Card, Col } from "react-bootstrap";
import styles from "./Blog.module.css";

const Blog = ({ blog }) => {
    const { title, img, description, blogExtUri } = blog;

    return (
        <Col>
            <a
                className={`${styles.blogExtLink}`}
                href={blogExtUri}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Card
                    className={`${"border-0 bg-light h-100 pb-3"} ${
                        styles.blogCard
                    }`}
                >
                    <Card.Img variant="top" src={img} />
                    <Card.Body>
                        <Card.Title className="h-25">{title}</Card.Title>
                        <Card.Text className={`${"h-50"} ${"text-dark"}`}>
                            {description.slice(0, 120)}
                            <span> ...</span>
                        </Card.Text>
                        <Card.Text className="h-25">
                            Read more{" "}
                            <span className={`${styles.extLinkIcon}`}></span>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </a>
        </Col>
    );
};

export default Blog;
