import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDiscord,
    faGithub,
    faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

import logo from "../../../assets/images/logo.jpg";

// const fbIcon = <FontAwesomeIcon icon={faFacebookF} />;
// const igIcon = <FontAwesomeIcon icon={faInstagram} />;
const liIcon = <FontAwesomeIcon icon={faLinkedinIn} />;
const ghIcon = <FontAwesomeIcon icon={faGithub} />;
// const ttIcon = <FontAwesomeIcon icon={faTwitter} />;
const dcIcon = <FontAwesomeIcon icon={faDiscord} />;
// const linkIcon = <FontAwesomeIcon icon={faExternalLinkAlt} />;

const Footer = () => {
    return (
        <div className="my-5">
            <Container>
                <div className="my-5">
                    <hr />
                </div>

                <div className="my-3">
                    <Row
                        xs={1}
                        sm={1}
                        md={2}
                        lg={2}
                        xl={4}
                        className="g-4 g-md-5 g-lg-5 g-xl-5"
                    >
                        <Col>
                            {/* original */}
                            <div className="d-flex align-items-center mb-lg-5 mb-md-5 mb-4">
                                <img
                                    src={logo}
                                    alt="Logo"
                                    style={{
                                        width: "64px",
                                        height: "64px",
                                        padding: "4px",
                                        borderRadius: "50%",
                                    }}
                                    className={`${styles.logoGlass}`}
                                />

                                <span
                                    className="ms-3 fs-2 fw-bold"
                                    style={{
                                        color: "#8a2be2",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <span title="Rakib Hasan Babu">RHB</span>
                                </span>
                            </div>

                            {/* testing */}
                            {/* <div className="d-flex align-items-center justify-center mb-4">
                                <img src={logoS} alt="Logo R" style={{ width: '28px', height: '28px' }} />

                                <span className="fs-2 fw-bold" style={{ color: '#8a2be2', display: 'flex', justifyContent: 'center', alignItems: 'center', fontStyle: 'italic' }}>
                                    <span title="Rakib Hasan Babu">HB</span>
                                </span>
                            </div> */}

                            <Nav>
                                <Nav.Item>
                                    <div className={`${styles.socialLink}`}>
                                        <Nav.Link
                                            className={`${styles.socialIcon}`}
                                            href="https://github.com/imRHB"
                                            target="_blank"
                                        >
                                            <span>{ghIcon}</span>
                                        </Nav.Link>
                                    </div>
                                </Nav.Item>
                                <Nav.Item>
                                    <div className={`${styles.socialLink}`}>
                                        <Nav.Link
                                            className={`${styles.socialIcon}`}
                                            style={{ padding: "10px 19px" }}
                                            href="https://www.linkedin.com/in/imRHB/"
                                            target="_blank"
                                        >
                                            <span>{liIcon}</span>
                                        </Nav.Link>
                                    </div>
                                </Nav.Item>
                                {/* <Nav.Item>
                                    <div className={`${styles.socialLink}`}>
                                        <Nav.Link className={`${styles.socialIcon}`} href="https://www.facebook.com/imRHB/" target="_blank"><span className="px-1">{fbIcon}</span></Nav.Link>
                                    </div>
                                </Nav.Item> */}
                                {/* <Nav.Item>
                                    <div className={`${styles.socialLink}`}>
                                        <Nav.Link className={`${styles.socialIcon}`} style={{ padding: '10px 19px' }} href="https://www.instagram.com/imprantu/" target="_blank"><span>{igIcon}</span></Nav.Link>
                                    </div>
                                </Nav.Item> */}
                                {/* <Nav.Item>
                                    <div className={`${styles.socialLink}`}>
                                        <Nav.Link className={`${styles.socialIcon}`} href="https://twitter.com/imprantu" target="_blank"><span>{ttIcon}</span></Nav.Link>
                                    </div>
                                </Nav.Item> */}
                                <Nav.Item>
                                    <div className={`${styles.socialLink}`}>
                                        <Nav.Link
                                            className={`${styles.socialIcon}`}
                                            style={{ padding: "10px 13px" }}
                                            href="https://discord.com/users/543524582662078464"
                                            target="_blank"
                                        >
                                            <span>{dcIcon}</span>
                                        </Nav.Link>
                                    </div>
                                </Nav.Item>
                            </Nav>
                        </Col>

                        <Col>
                            <div>
                                <h4 className="fw-bold mb-3">Explore</h4>
                                <Nav
                                    className={`${
                                        styles.footerLink
                                    } ${"flex-column"}`}
                                >
                                    <Link to="/home">Home</Link>
                                    <Link to="/projects">Projects</Link>
                                </Nav>
                            </div>
                        </Col>

                        <Col>
                            <div>
                                <h4 className="fw-bold mb-3">Related Links</h4>
                                <Nav
                                    className={`${"flex-column"} ${
                                        styles.footerLink
                                    }`}
                                >
                                    {/* <Link to="/hire">Hire Me</Link> */}
                                    <Link to="/about">About</Link>
                                    <Link to="/blogs">Blogs</Link>
                                    <Link to="/contact">Contact</Link>
                                    {/* <Link to="/achievements">Achievements</Link> */}
                                    {/* <Link to="/activities">Activities</Link> */}
                                </Nav>
                            </div>
                        </Col>

                        <Col>
                            <h4 className="fw-bold mb-3">Bio</h4>
                            {/* <p className="text-muted">Exploring and focusing on MERN Stack development and preparing myself as a MERN Stack Developer.</p> */}
                            <p
                                className="text-muted"
                                style={{ textAlign: "justify" }}
                            >
                                I'm a passionate Web Developer. I'm working on
                                different kinds of web technologies, libraries,
                                packages, and tools.
                                <br />I love to develop new products and explore
                                new technologies. Also, I love to play video
                                games and hang out with friends in my free time.
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className="mt-5">
                    <hr />
                </div>
            </Container>

            <Container>
                <div className="text-center mt-5">
                    <p className="text-muted">
                        Designed and Developed by{" "}
                        <span title="Rakib Hasan Babu">RHB</span>
                    </p>
                    <p className="text-muted">
                        <span>Copyright &copy; </span>
                        <a
                            href="https://www.rhbabu.dev/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`${styles.redUrl}`}
                        >
                            <span className="fw-normal text-monospace">
                                rhbabu.dev
                            </span>
                        </a>
                    </p>
                </div>
            </Container>
        </div>
    );
};

export default Footer;
