import React, { useEffect } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import AOS from 'aos';
import styles from './AboutMe.module.css';

// import codingGif from '../../assets/images/coding.gif';
import UserProfile from "../../components/UserProfile/UserProfile";

// const linkIcon = <FontAwesomeIcon icon={faExternalLinkAlt} />;

const careerList = [
    {
        id: 1,
        company: 'TECH V ZERO',
        designation: 'Junior Software Engineer',
        contract: 'Full-time',
        from: 'May 2022',
        to: 'Present',
        location: {
            state: 'New Delhi',
            country: 'India'
        },
        jobType: 'Remote'
    },
    {
        id: 2,
        company: 'Quantanite',
        designation: 'Analyst',
        contract: 'Full-time',
        from: 'May 2020',
        to: 'Jul 2020',
        location: {
            state: 'Dhaka',
            country: 'Bangladesh'
        },
        jobType: 'Remote'
    }
];

const educationList = [
    {
        id: 1,
        institution: 'Daffodil International University',
        degree: 'Bachelor of Science',
        fieldOfStudy: 'Computer Science & Engineering',
        startDate: 2015,
        endDate: 2019,
        activities: {
            title: 'Research on Human-Computer Interaction (HCI)',
            description: 'Analyzing Smartphone Users’ Application Adoption Behavior - An Approach for Achieving Wider Acceptance'
        }
    }
];

const AboutMe = () => {
    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1500,
            easing: 'ease-out-quad'
        })
    }, []);

    return (
        <div className={`${'my-4 pt-4 pb-5'} ${styles.aboutSec}`}>
            <Container data-aos="slide-up">
                <Row xs={1} sm={1} md={1} lg={2} xl={2} className="g-4 g-md-5 g-lg-5 g-xl-5">
                    <Col>
                        {/* <div className={`${styles.alterImgSec}`}>
                            <img src={codingGif} alt="Coding" className="w-100" />
                        </div> */}

                        <div className={`${'d-flex justify-content-center'} ${styles.userProfile}`}>
                            <UserProfile />
                        </div>
                    </Col>

                    <Col>
                        <div className={`${'mt-5 m-md-0 m-lg-0'}`}>
                            {/* <p className={`${styles.aboutInfo}`}>
                                <span className={`${'fs-3 fw-bold'} ${styles.greetTitle}`}>Hi!</span>
                                <br />
                                I'm Rakib Hasan Babu from Dhaka, Bangladesh. I'm an undergraduate student from Daffodil International University under the Department of Computer Science and Engineering.
                            </p>
                            <p className={`${styles.aboutInfo}`}>
                                At present, I'm exploring and studying Web Development. I'm learning MREN Stack Development and preparing myself as a MERN Stack Developer. Based on my skills, I've completed a few projects. You can take a look at my <Link to="/projects" className={`${styles.redUrl}`}>projects</Link> section or check <a className={`${styles.redUrl}`} href="https://github.com/imRHB?tab=repositories" target="_blank" rel="noopener noreferrer">GitHub repositories<span className="fs-6 ms-1">{linkIcon}</span></a>.
                            </p>
                            <p className={`${styles.aboutInfo}`}>
                                I am skilled in <span className="fw-bold"><code>HTML5, CSS3, JavaScript, ES6, React JS, React Router, React Bootstrap, Node JS, Express JS, MongoDB,</code> <span className="fw-normal"> and</span> <code>Firebase Authentication</code></span>.
                                <br />
                                I'm also familiar with <span className="fw-bold"><code>JWT, React Native, Redux, Stripe, Tailwind CSS,</code><span className="fw-normal"> and</span> <code>Material UI</code></span>.
                                <br />
                                Besides these, I'm used to a few tools, like - <span className="fw-bold">Git, VS Code, Figma, Jira, Heroku, Netlify,</span> and<span className="fw-bold"> Chrome Dev Tool</span>.
                            </p>
                            <p className={`${'bg-light p-1 rounded'} ${styles.aboutInfo}`}>
                                I'm looking for an environment where I'll learn something and be able to show my skills.
                            </p> */}


                            {/* new about with details */}

                            <div className={`${styles.aboutInfo}`}>
                                <span className={`${'fs-2 fw-bold'} ${styles.greetTitle}`}>Hi!</span>
                                <p>I'm Rakib Hasan Babu from Dhaka, Bangladesh.</p>
                            </div>
                            <div className={`${styles.aboutInfo}`}>
                                <div className={`${'fs-4 fw-bold text-uppercase'}`}>Education</div>
                                {
                                    educationList.map((education) => (
                                        <div key={education.id} className="mt-1 mb-3">
                                            <span className="h5">{education.institution}</span>
                                            <br />
                                            <span className="h6">{education.degree}, {education.fieldOfStudy}</span>
                                            <br />
                                            <span className="text-muted">{education.startDate} - {education.endDate}</span>
                                            <br />
                                            <span className="h6 lh-lg">Activity</span>
                                            <br />
                                            <div className="border-start border-3 border-gray bg-light rounded-start ps-3 py-2 pe-1">
                                                <span className="text-muted">{education.activities.title}</span>
                                                <br />
                                                <span className="text-muted">{education.activities.description}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                                {/* <p>I've completed my Bachelor's degree from Daffodil International University in the Department of Computer Science &amp; Engineering.</p> */}
                            </div>
                            <div className={`${styles.aboutInfo}`}>
                                <div className={`${'fs-4 fw-bold text-uppercase'}`}>
                                    Learning <span className="fs-6">
                                        <Badge bg="dark" className="fw-light">
                                            <small>
                                                Web Development
                                            </small>
                                        </Badge>
                                    </span>
                                </div>
                                <p>
                                    On July 1, 2021, I started to learn Web Development. The course was offered by Programming Hero. It was a beginner-level course that started from zero to a junior-level standard. Within five months, I completed the course successfully and learned the basics of MERN Stack. I finished a few projects with this tech stack. Besides this, to the extent of this course, I completed a long-term team project, where I learned and familiarized the office culture and working process. That's how my web development learning started. Still, I'm learning and exploring web development.
                                </p>
                            </div>
                            <div className={`${styles.aboutInfo}`}>
                                <div className={`${'fs-4 fw-bold text-uppercase'}`}>Skills</div>
                                <p>I am skilled in <span className="fw-bold"><code>HTML5, CSS3, Bootstrap, JavaScript, ES6, React JS, React Router, Node JS, Express JS, MongoDB,</code> <span className="fw-normal"> and</span> <code>Firebase Authentication</code></span>.
                                    <br />
                                    I'm also familiar with <span className="fw-bold"><code>JWT, React Native, Redux, Stripe, Tailwind CSS,</code><span className="fw-normal"> and</span> <code>Material UI</code></span>.
                                    <br />
                                    Besides these, I'm used to a few tools, like - <span className="fw-bold"><code>Git, VS Code, Figma, Jira, Heroku, Netlify,</code></span> and<span className="fw-bold"> <code>Chrome Dev Tool</code></span>.
                                    <br />
                                    Currently, I'm working on <span className="fw-bold"><code>Vue JS, Nuxt JS, Next JS,</code></span> and necessary packages.</p>
                            </div>
                            <div className={`${styles.aboutInfo}`}>
                                <div className={`${'fs-4 fw-bold text-uppercase'}`}>
                                    Experience
                                </div>
                                {
                                    careerList.map((career) => (
                                        <div key={career.id} className="mt-1 mb-3">
                                            <span className="h5">{career.designation}</span>
                                            <br />
                                            <span className="h6">{career.company} - {career.contract}</span>
                                            <br />
                                            <span className="text-muted">{career.from} - {career.to}</span>
                                            <br />
                                            <span className="text-muted">{career.location.state}, {career.location.country}</span>
                                            {/* <p className="h5 lh-1">{career.designation}</p>
                                            <p className="h6 lh-1">{career.company} - {career.contract}</p>
                                            <p className="lh-1">{career.from} - {career.to}</p>
                                            <p className="lh-1">{career.location.state}, {career.location.country}</p> */}
                                            {/* <h4>{career.designation}</h4>
                                            <h5>{career.company} - {career.contract}</h5>
                                            <h6>{career.from} - {career.to}</h6>
                                            <h6>{career.location.state}, {career.location.country}</h6> */}
                                        </div>
                                    ))
                                }
                                {/* <p>I started my career as an Analyst at Quantanite. After that, I started my development career by joining TECH V ZERO as a Junior Software Engineer.</p> */}
                            </div>
                            <div className={`${styles.aboutInfo}`}>
                                <div className={`${'fs-4 fw-bold text-uppercase'}`}>Love To Do</div>
                                <p>I love programming, developing new products, and exploring new technologies. Also, I love to play video games and hang out with friends in my free time.</p>
                            </div>
                            {/* <div className={`${styles.aboutInfo}`}>
                                <span className={`${'fs-4 fw-bold text-uppercase'}`}>Training &amp; Certificates</span>
                                <ul>
                                    <li className="fw-bold">Complete Web Development Course with Jhankar Mahbub</li>
                                    <span>July 2021 - November 2021</span>
                                    <li className="fw-bold">Complete Web Development <span className="fw-normal">(Certificate of Completion with Excellence)</span></li>
                                    <span>Programming Hero</span>
                                    <li className="fw-bold">End Game Hero <span className="fw-normal">(Letter of Recommendation)</span></li>
                                    <span>Job Placement, Programming Hero</span>
                                </ul>
                            </div> */}
                            <div className={`${'border-top border-end border-bottom border-3 border-gray bg-light rounded-end'} ${styles.aboutInfo}`}>
                                <div className={`${'fs-4 fw-bold text-uppercase px-1'}`}>Goal of 2022 <span className="fs-6">
                                    <Badge bg="dark" className="fw-light">
                                        <small>
                                            Familiar
                                        </small>
                                    </Badge>
                                </span>
                                </div>
                                <ul>
                                    <li><span className="fs-5 fw-bold"><code>Vue JS</code></span></li>
                                    <li><span className="fs-5 fw-bold"><code>Nuxt JS</code></span></li>
                                    <li><span className="fs-5 fw-bold"><code>Next JS</code></span></li>
                                    <li><span className="fs-5 fw-bold"><code>Tailwind CSS</code></span></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default AboutMe;