import React from 'react';
// import AOS from 'aos';
import { Container } from 'react-bootstrap';
// import styles from './Contact.module.css';
import ContactForm from "../../components/ContactForm/ContactForm";

const Contact = () => {
    /* useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: 'ease-out-quad'
        })
    }, []); */

    return (
        <div className="my-4 py-4">
            <Container>
                <ContactForm contactTitle="You can ask your query" />
            </Container>
        </div>
    );
};

export default Contact;