import { useEffect, useState } from "react";

const useBlogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [publishedBlogs, setPublishedBlogs] = useState([]);
    const [blog, setBlog] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        fetch(`https://rhbabu.onrender.com/blogs?published=true`)
            .then((res) => res.json())
            .then((data) => {
                setPublishedBlogs(data);
                setLoading(false);
            });
    }, []);

    const singleBlog = (_id) => {
        setLoading(true);

        fetch(`https://rhbabu.onrender.com/blogs/${_id}`)
            .then((res) => res.json())
            .then((data) => {
                setBlog(data);
                setLoading(false);
            });
    };

    useEffect(() => {
        // setLoading(true);

        fetch("https://rhbabu.onrender.com/blogs")
            .then((res) => res.json())
            .then((data) => {
                setBlogs(data);
                // setLoading(false);
            });
    }, [blog]);

    /* useEffect((_id) => {
        setLoading(true);

        fetch(`https://rhbabu.onrender.com/blogs/${_id}`)
            .then(res => res.json())
            .then(data => {
                setBlog(data);
                setLoading(false);
            });
    }, []); */

    return {
        blogs,
        setBlogs,
        publishedBlogs,
        setPublishedBlogs,
        blog,
        setBlog,
        singleBlog,
        loading,
        setLoading,
    };
};

export default useBlogs;
