import React, { useEffect } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import AOS from "aos";
import Project from "../Project/Project";
import useFetch from "../../../hooks/useFetch";
// import styles from './Projects.module.css';

const Projects = () => {
    // const [projects, setProjects] = useState([]);
    // const [loading, setLoading] = useState(false);

    const { data: projects, loading } = useFetch(
        "https://rhbabu.onrender.com/projects"
    );

    /* useEffect(() => {
        setLoading(true);

        fetch('https://rhbabu.onrender.com/projects')
            .then(res => res.json())
            .then(data => {
                setProjects(data);
                setLoading(false);
            });
    }, []); */

    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: "ease-out-quad",
        });
    }, []);

    return (
        <div className="my-4 pt-4 pb-5">
            <Container>
                {loading ? (
                    <Container
                        style={{
                            minHeight: "60vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Spinner
                            animation="grow"
                            variant=""
                            size="sm"
                            style={{ color: "#8a2ce2b3", margin: "0 4px" }}
                        />
                        <Spinner
                            animation="grow"
                            variant=""
                            size="sm"
                            style={{ color: "#8a2ce2cc", margin: "0 4px" }}
                        />
                        <Spinner
                            animation="grow"
                            variant=""
                            size="sm"
                            style={{ color: "#8a2ce2e6", margin: "0 4px" }}
                        />
                    </Container>
                ) : (
                    <>
                        {projects?.length > 0 ? (
                            <div data-aos="slide-up">
                                <Row
                                    xs={1}
                                    sm={1}
                                    md={2}
                                    lg={3}
                                    xl={3}
                                    xxl={3}
                                    className="g-4 g-md-5 g-lg-5 g-xl-5 pb-5"
                                >
                                    {projects?.map((project) => (
                                        <Project
                                            key={project._id}
                                            project={project}
                                        ></Project>
                                    ))}
                                </Row>
                            </div>
                        ) : (
                            <Container
                                style={{
                                    minHeight: "60vh",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <p className="fs-4 text-muted">
                                    No Project Found!
                                </p>
                            </Container>
                        )}
                    </>
                )}
            </Container>
        </div>
    );
};

export default Projects;
