import React from 'react';
import { Col, Container, Modal, Nav, Row } from 'react-bootstrap';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ProjectModal.module.css';

const linkIcon = <FontAwesomeIcon icon={faArrowUpRightFromSquare} />;

const ProjectModal = (props) => {
    const { title, type, img1, img2, img3, img1Title, img2Title, img3Title, features, technologies, livePreview, githubClient } = props.project;

    return (
        <div>
            <Container>
                <Modal
                    className={`${styles.modalGlass}`}
                    {...props}
                    size="lg"
                    // backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {/* {title} */}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row xs={1} sm={1} md={1} lg={2} xl={2} className="g-4 mx-1" style={{ height: '100%' }}>
                                <Col xs={12} sm={12} md={12} lg={4} xl={4} className="bg-light border border-2 rounded-3">
                                    <div className="my-2">
                                        <div className={`${styles.imgDiv}`}>
                                            <img src={img1} alt={img1Title} title={img1Title} />
                                        </div>
                                        <hr />
                                        <div className={`${styles.imgDiv}`}>
                                            <img src={img2} alt={img2Title} title={img2Title} />
                                        </div>
                                        <hr />
                                        <div className={`${styles.imgDiv}`}>
                                            <img src={img3} alt={img3Title} title={img3Title} />
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div>
                                        <Modal.Title className={`${'d-flex justify-content-between align-items-center'}`}>
                                            <span className="fs-2 fw-bold" style={{ color: '#8A2BE2' }}>{title}</span>

                                            {/* <small className={`${styles.tagBody}`}>
                                                <span className={`${styles.tagTitle}`}>{type}</span>
                                            </small> */}

                                            <div className={`${'text-light'} ${styles.tagBody}`}>
                                                <p className={`${'m-0 fs-6 fw-normal'}`}>{type}</p>
                                            </div>
                                        </Modal.Title>
                                    </div>
                                    <div>
                                        <hr />
                                    </div>
                                    <div>
                                        <p className="fs-5 fw-bold">Features:</p>
                                        <ul>
                                            {
                                                features.map(feature => <li
                                                    key={feature}
                                                >{feature}</li>)
                                            }
                                        </ul>
                                    </div>
                                    <div>
                                        <hr />
                                    </div>
                                    <div>
                                        <p className="fs-5 fw-bold">Technologies:</p>
                                        <div>
                                            {
                                                technologies.map(tech => <p
                                                    key={tech}
                                                    className={`${styles.techItem}`}
                                                >{tech}</p>)
                                            }
                                        </div>
                                    </div>
                                    <div className="my-4 d-flex justify-content-between">
                                        <Nav.Link
                                            target="_blank"
                                            className={`${styles.previewLink} ${'px-3 py-2'}`}
                                            href={livePreview}
                                        >
                                            Preview<span className="ms-3">{linkIcon}</span>
                                        </Nav.Link>

                                        <Nav.Link
                                            target="_blank"
                                            className={`${styles.previewLink} ${'px-3 py-2'}`}
                                            href={githubClient}
                                        >
                                            GitHub [client]<span className="ms-3">{linkIcon}</span>
                                        </Nav.Link>

                                        {/* <Nav className="mt-5">
                                            <div className={`${styles.downloadLink}`}>
                                                <Link className={`${styles.downloadIcon}`} to="/Rakib_Hasan_Babu-Web_Developer-Resume.pdf" target="_blank" download><span className="me-3">{linkIcon}</span>RESUME</Link>
                                            </div>
                                        </Nav> */}
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </div>
                </Modal>
            </Container>
        </div>
    );
};

export default ProjectModal;