import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AOS from 'aos';
import styles from './Hire.module.css';

import tools from '../../assets/images/tools.png';
// import codingGif from '../../assets/images/coding.gif';
import ContactForm from "../../components/ContactForm/ContactForm";

const Hire = () => {
    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 2000,
            easing: 'ease-out-quad'
        })
    }, []);

    return (
        <div className="my-4">
            <Container>
                <Row xs={1} sm={1} md={1} lg={2} xl={2} className="g-4 g-md-5 g-lg-5 g-xl-5">
                    <Col>
                        <div data-aos="fade-in" className={`${'text-center'} ${styles.logo}`}>
                            {/* <img src={work} alt="Work" style={{ width: '80%' }} /> */}
                            <img src={tools} alt="Handle Project" className="w-100" />
                        </div>
                    </Col>

                    <Col>
                        <div className="my-5">
                            <ContactForm contactTitle="Let me handle your project" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Hire;