import React, { useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import rrIcon from '../../../../assets/images/icon/rr-icon.ico';
import netlifyIcon from '../../../../assets/images/icon/netlify-icon.ico';
import expoIcon from '../../../../assets/images/icon/expo-icon.png';
import stripeIcon from '../../../../assets/images/icon/stripe-icon.png';
import jwtIcon from '../../../../assets/images/icon/jwt-icon.png';
import styles from './Skills.module.css';
import AOS from "aos";

const Skills = () => {
    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: 'ease-out-quad'
        })
    }, []);

    return (
        <div>
            <Container>
                <div className="text-center my-5 py-4" data-aos="slide-up">
                    <h2 className="fw-bold" style={{ color: '#8a2be2' }}>SKILLS</h2>
                    <p className="mx-auto fs-5" style={{ maxWidth: '578px' }}><code>Programming Languages, Frameworks, Libraries, Tools, and Technologies currently I'm working on.</code></p>
                </div>

                <Row xs={1} sm={2} md={2} lg={3} xl={4} xxl={4} className="g-4 g-md-4 g-lg-4 g-xl-4 g-xxl-5" data-aos="slide-up">
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-html5-plain colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">HTML5</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-css3-plain colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">CSS3</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-bootstrap-plain colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">Bootstrap</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span>
                                <i className="devicon-tailwindcss-plain colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">Tailwind CSS</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-javascript-plain colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">JavaScript</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-react-original colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">React JS</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-react-original colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">React Native</h4>
                        </div>
                    </Col>
                    {/* <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><img src={expoIcon} style={{ width: 'calc(1.35rem + 1.4vw)', height: 'auto' }} alt="Expo Logo" /></span>
                            <h4 className="fw-bold ms-3">Expo</h4>
                        </div>
                    </Col> */}
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-nodejs-plain colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">Node JS</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-express-original colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">Express JS</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-mongodb-plain colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">MongoDB</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><img src={stripeIcon} style={{ width: 'calc(1.35rem + 1.4vw)', height: 'auto' }} alt="Stripe Logo" /></span>
                            <h4 className="fw-bold ms-3">Stripe</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><img src={rrIcon} style={{ width: 'calc(1.35rem + 1.4vw)', height: 'auto' }} alt="React Router Logo" /></span>
                            <h4 className="fw-bold ms-3">React Router</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-firebase-plain colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">Firebase Auth</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><img src={jwtIcon} style={{ width: 'calc(1.35rem + 1.4vw)', height: 'auto' }} alt="JWT Logo" /></span>
                            <h4 className="fw-bold ms-3">JWT</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-vscode-plain colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">VS Code</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-git-plain colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">Git</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span>
                                <i className="devicon-jira-plain colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">Jira Software</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><img src={netlifyIcon} style={{ width: 'calc(1.35rem + 1.4vw)', height: 'auto' }} alt="Netlify Logo" /></span>
                            <h4 className="fw-bold ms-3">Netlify</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.devSkill}`}>
                            <span><i className="devicon-heroku-plain colored fs-1"></i></span>
                            <h4 className="fw-bold ms-3">Heroku</h4>
                        </div>
                    </Col>
                </Row>

                <div className="mt-5 pt-5">
                    <hr />
                </div>
            </Container>
        </div>
    );
};

export default Skills;