import emailjs from 'emailjs-com';
import React, { useRef, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import styles from './ContactForm.module.css';

const ContactForm = ({ contactTitle }) => {
    const form = useRef();
    const [show, setShow] = useState(false);

    const sendEmail = e => {
        e.preventDefault();

        emailjs.sendForm('service_3487zzq', 'template_4conang', form.current, 'user_t3gslzZHVaWPow8xKZ7En')
            .then(result => {
                setShow(true);
                // console.log(result.text);
            },
                error => {
                    // console.log(error.text);
                });

        e.target.reset();
    };

    return (
        <div>
            <div className="text-center">
                <h2 className="fw-bold text-uppercase" style={{ color: '#8a2be2' }}>{contactTitle}</h2>
            </div>

            <div className={`${'my-5'} ${styles.alertArea}`}>
                {
                    show && <Alert
                        variant="success"
                        className="text-center"
                        onClose={() => setShow(false)}
                        dismissible
                    >
                        Thanks for reaching out. I'll contact you soon. Please get in touch with that email.
                    </Alert>
                }
            </div>

            <Form ref={form} onSubmit={sendEmail} className={`${'mx-auto my-5 text-center'} ${styles.formArea}`}>
                <Row xs={1} sm={1} md={1} lg={2} xl={2} className="mb-0">
                    <Form.Group as={Col} controlId="formGridName">
                        <Form.Label></Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            required
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label></Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            required
                        />
                    </Form.Group>
                </Row>

                <Row className="mb-0">
                    <Form.Group as={Col} controlId="formGridSubject">
                        <Form.Label></Form.Label>
                        <Form.Control
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            required
                        />
                    </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label></Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={7}
                        name="message"
                        placeholder="Your Message"
                        required
                    />
                </Form.Group>

                <Button
                    variant=""
                    type="submit"
                    className={`${'mt-3'} ${styles.btnSendMsg}`}
                >
                    Send Message
                </Button>
            </Form>
        </div>
    );
};

export default ContactForm;