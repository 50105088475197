import React, { useEffect } from 'react';
import { Container, Row, Spinner } from 'react-bootstrap';
import AOS from 'aos';

import Blog from "../Blog/Blog";

import useBlogs from "../../../hooks/useBlogs";

const Blogs = () => {
    const { publishedBlogs, loading } = useBlogs();

    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: 'ease-out-quad'
        })
    }, []);

    return (
        <div className={`${'my-4 py-4'}`}>
            <Container>
                {
                    loading ? <Container style={{ minHeight: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Spinner animation="grow" variant="" size="sm" style={{ color: '#8a2ce2b3', margin: '0 4px' }} />
                        <Spinner animation="grow" variant="" size="sm" style={{ color: '#8a2ce2cc', margin: '0 4px' }} />
                        <Spinner animation="grow" variant="" size="sm" style={{ color: '#8a2ce2e6', margin: '0 4px' }} />
                    </Container>
                        :
                        <>
                            {
                                publishedBlogs?.length > 0 ? <div data-aos="slide-up">
                                    <Row xs={1} sm={1} md={2} lg={3} xl={3} className="g-4 g-md-5 g-lg-5 g-xl-5 pb-5">
                                        {
                                            publishedBlogs.map(blog => <Blog
                                                key={blog._id}
                                                blog={blog}
                                            ></Blog>)
                                        }
                                    </Row>
                                </div>
                                    :
                                    <Container style={{ minHeight: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                        <p className="fs-4 text-muted">No Blog Found!</p>
                                    </Container>
                            }
                        </>
                }
            </Container>
        </div>
    );
};

export default Blogs;