import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Nav, Row } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
// import Typical from 'react-typical';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faDownload, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import styles from "./Banner.module.css";

// import logo from '../../../assets/images/logo.png';
import codingGif from "../../../assets/images/coding.gif";

const mapIcon = <FontAwesomeIcon icon={faMapMarkerAlt} />;
// const fbIcon = <FontAwesomeIcon icon={faFacebookF} />;
const dcIcon = <FontAwesomeIcon icon={faDiscord} />;
// const igIcon = <FontAwesomeIcon icon={faInstagram} />;
const liIcon = <FontAwesomeIcon icon={faLinkedinIn} />;
const downloadIcon = <FontAwesomeIcon icon={faDownload} />;

const Banner = () => {
    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 2000,
            easing: "ease-out-quad",
        });
    }, []);

    return (
        <div className="my-4 pt-4">
            <Container>
                <Row
                    xs={1}
                    sm={1}
                    md={1}
                    lg={2}
                    xl={2}
                    className="g-4 g-md-5 g-lg-5 g-xl-5"
                    data-aos="slide-down"
                >
                    <Col>
                        <div className={`${styles.banner}`}>
                            <div className="p-5">
                                <div className="mt-3">
                                    <div>
                                        <h1 className="fw-bold">
                                            Hi, I'm{" "}
                                            <span style={{ color: "#8a2be2" }}>
                                                Rakib
                                            </span>
                                            !
                                        </h1>
                                    </div>

                                    <div>
                                        <p
                                            className="fs-4"
                                            style={{ fontWeight: 600 }}
                                        >
                                            <span>I'm a Web Developer!</span>
                                            {/* <Typical
                                                steps={['Web Developer', 2500, '', 1500, 'Front End Developer', 2500, '', 1500, 'React Developer', 2500, '', 1500, 'Full Stack Developer', 2500, '', 1500]}
                                                wrapper="code"
                                                loop={Infinity}
                                            /> */}
                                        </p>
                                    </div>

                                    <div>
                                        <p>
                                            <span className="me-3">
                                                {mapIcon}
                                            </span>
                                            Dhaka, Bangladesh
                                        </p>
                                    </div>

                                    {/* <div>
                                        <Badge pill bg="primary">Available for Hiring</Badge>
                                    </div> */}

                                    <Nav className="mt-5">
                                        <div
                                            className={`${styles.downloadLink}`}
                                        >
                                            <Link
                                                className={`${styles.downloadIcon}`}
                                                to="/Rakib_Hasan_Babu-Web_Developer-Resume.pdf"
                                                target="_blank"
                                                download
                                            >
                                                <span className="me-3">
                                                    {downloadIcon}
                                                </span>
                                                RESUME
                                            </Link>
                                        </div>
                                    </Nav>
                                </div>
                            </div>
                        </div>

                        <div className={`${styles.banner} ${"my-5"}`}>
                            <div className="p-5">
                                <div className="mb-4">
                                    <p className="fs-5 fw-bold text-primary">
                                        CONTACT ME
                                    </p>
                                </div>

                                <Nav>
                                    <Nav.Item>
                                        <div className={`${styles.socialLink}`}>
                                            <Nav.Link
                                                className={`${styles.socialIcon}`}
                                                href="https://www.linkedin.com/in/imRHB/"
                                                target="_blank"
                                            >
                                                <span>{liIcon}</span>
                                            </Nav.Link>
                                        </div>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <div className={`${styles.socialLink}`}>
                                            <Nav.Link className={`${styles.socialIcon}`} href="https://www.facebook.com/imRHB/" target="_blank"><span className="px-1">{fbIcon}</span></Nav.Link>
                                        </div>
                                    </Nav.Item> */}
                                    <Nav.Item>
                                        <div className={`${styles.socialLink}`}>
                                            <Nav.Link
                                                className={`${styles.socialIcon}`}
                                                style={{ padding: "10px 13px" }}
                                                href="https://discord.com/users/543524582662078464"
                                                target="_blank"
                                            >
                                                <span>{dcIcon}</span>
                                            </Nav.Link>
                                        </div>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <div className={`${styles.socialLink}`}>
                                            <Nav.Link className={`${styles.socialIcon}`} href="https://www.instagram.com/imprantu/" target="_blank"><span>{igIcon}</span></Nav.Link>
                                        </div>
                                    </Nav.Item> */}
                                </Nav>
                            </div>
                        </div>
                    </Col>

                    <Col>
                        <div className={`${styles.banner}`}>
                            <div className={`${styles.logo}`}>
                                {/* <img src={logo} alt="Logo" /> */}
                                <img
                                    src={codingGif}
                                    alt="Coding"
                                    className="w-100"
                                />
                            </div>
                        </div>
                        {/* <div className={`${'mt-5'} ${styles.banner}`}>
                            <div className="px-5 py-4">
                                <p>Status: Available</p>
                            </div>
                        </div> */}
                    </Col>
                </Row>

                <div className="mt-5 pt-5">
                    <hr />
                </div>
            </Container>
        </div>
    );
};

export default Banner;
